<template>
  <!-- Navbar -->
  <div>
    <v-overlay :value="dialog">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <nav class="navbar navbar-expand-lg bg-white navbar-absolute navbar-transparent">
      <div class="container">
        <div class="navbar-translate">
          <a
            class="navbar-brand"
            data-placement="bottom"
            @click="$router.push('/home')"
          >
            LETS Connect
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-controls="navigation-index"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-bar top-bar" />
            <span class="navbar-toggler-bar middle-bar" />
            <span class="navbar-toggler-bar bottom-bar" />
          </button>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->
    <div
      class="page-header header-filter"
      filter-color="black"
    >
      <div
        class="page-header-image"
        :style="{'background-image': 'url('+require('../../assets/lc_img/auth_background.jpg')+')'}"
      />
      <div class="contentWrapper">
        <div
          class="content"
          :style="{'width': '100%'}"
        >
          <div class="container">
            <div class="row">
              <div class="col-md-4 ml-auto mr-auto">
                <div class="info info-horizontal">
                  <div class="icon icon-primary">
                    <i class="fas fa-comment-alt" />
                  </div>
                  <div class="description">
                    <h5 class="info-title">
                      SMS Gateway
                    </h5>
                    <p class="description">
                      Send and receive SMS messages globally. Simple yet powerful.
                    </p>
                  </div>
                </div>
                <div class="info info-horizontal">
                  <div class="icon icon-primary">
                    <i class="fas fa-photo-video" />
                  </div>
                  <div class="description">
                    <h5 class="info-title">
                      Rich Messaging
                    </h5>
                    <p class="description">
                      Create and send rich multimedia experiences by RCS or messaging apps.
                    </p>
                  </div>
                </div>
                <div class="info info-horizontal">
                  <div class="icon icon-info">
                    <i class="fas fa-hand-holding-usd" />
                  </div>
                  <div class="description">
                    <h5 class="info-title">
                      No hidden charges
                    </h5>
                    <p class="description">
                      We'll beat any cheaper comparable quotes.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mr-auto">
                <div class="card card-signup">
                  <div class="card-body">
                    <h4 class="card-title text-center">
                      Welcome back!
                    </h4>
                    <div class="social text-center">
                      <button
                        class="btn btn-icon btn-round btn-twitter"
                        @click="loginWithTwitter"
                      >
                        <i class="fab fa-twitter" />
                      </button>
                      <button
                        class="btn btn-icon btn-round btn-google"
                        @click="loginWithGoogle"
                      >
                        <i class="fab fa-google" />
                      </button>
                      <button
                        class="btn btn-icon btn-round btn-facebook"
                        @click="loginWithFacebook"
                      >
                        <i class="fab fa-facebook-square" />
                      </button>
                      <button
                        class="btn btn-icon btn-round btn-github"
                        @click="loginWithGithub"
                      >
                        <i class="fab fa-github" />
                      </button>
                      <button
                        class="btn btn-icon btn-round btn-linkedin"
                        @click="loginWithLinkedIn"
                      >
                        <i class="fab fa-linkedin" />
                      </button>
                      <h5 class="card-description">
                        or be classical
                      </h5>
                    </div>
                    <form
                      class="form"
                      method=""
                      action=""
                    >
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="now-ui-icons users_circle-08" /></span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Your Email..."
                          autocomplete="email"
                          :value="fields.email.value"
                          @input="_updateFieldValue('email', $event.target.value)"
                          @blur="_markAsDirty('email')"
                        >
                      </div>
                      <p class="inputError">
                        {{
                          fields.email.isDirty && !fields.email.isValid? 'Please enter a valid email': ''
                        }}
                      </p>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-key" /></span>
                        </div>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Password"
                          autocomplete="password"
                          :value="fields.password.value"
                          @input="_updateFieldValue('password', $event.target.value)"
                          @blur="_markAsDirty('password')"
                        >
                      </div>
                      <p class="inputError">
                        {{
                          fields.password.isDirty && !fields.password.isValid? 'Please enter your password': ''
                        }}
                      </p>
                      <!-- If you want to add a checkbox to this form, uncomment this code -->
                      <!-- <div class="form-check">
                      <label class="form-check-label">
                        <input
                          class="form-check-input"
                          type="checkbox"
                        >
                        <span class="form-check-sign" />
                        I agree to the terms and
                        <a href="#something">conditions</a>.
                      </label>
                    </div> -->
                      <div class="card-footer text-center">
                        <a
                          href="#"
                          class="btn btn-primary btn-round btn-lg"
                          @click="_submitLoginForm"
                        >Login</a>
                      </div>
                      <div class="authLinks">
                        <div
                          class="leftLink"
                          @click="$router.push('/forget-password')"
                        >
                          Forget Password?
                        </div>
                        <div
                          class="rightLink"
                          @click="$router.push('/register')"
                        >
                          Create new account
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class=" container ">
          <nav>
            <ul>
              <li>
                <a
                  href="#"
                  @click="$router.push('/home')"
                >
                  Lets Connect
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click="$router.push('/about-us')"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click="$router.push('/contact-us')"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
          <div
            id="copyright"
            class="copyright"
          >
            &copy;
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
  import * as firebase from 'firebase'
  import axios from 'axios'
  import HttpConnector from '../../utils/http-connector'
  import jwtDecode from 'jwt-decode'

  export default {
    name: 'LoginPage',

    components: {},

    data () {
      return {
        httpConnector: new HttpConnector(),
        apiEndpoint: process.env.VUE_APP_API_BASE_URL,
        apiCode: process.env.VUE_APP_API_CODE,
        firebaseCloudFunctionBaseUrl: process.env.VUE_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL,
        dialog: false,
        fields: {
          email: {
            value: '',
            isDirty: false,
            isValid: false,
          },
          password: {
            value: '',
            isDirty: false,
            isValid: false,
          },
        },
        isFormValid: false,
        snackbar: false,
        notification: false,
        generalError: {
          isVisible: false,
          errorText: '',
        },
      }
    },
    computed: {},
    created () {
      if (this.$route && this.$route.query && this.$route.query.email) {
        this.fields.email.value = this.$route.query.email
      }
    },
    methods: {
      async sendUserDataToBackend (token) {
        try {
          await this.httpConnector.makeEncryptedRequest('post', `${this.apiEndpoint}user/signin?code=${this.apiCode}`)
        } catch (error) {
          // console.log('error :>> ', error)
        }
      },
      async handleDuplicateAccountError (loginError, provider) {
        this.notification = loginError.message
        this.snackbar = true
        this.dialog = false
      },
      async setUserAndRedirectToDashboard (email) {
        localStorage.setItem('username', email)
        this.$router.push('/pages/senders')
        this.dialog = false
      },
      async loginWithTwitter () {
        this.dialog = true
        const provider = new firebase.default.auth.TwitterAuthProvider()
        firebase.default.auth().signInWithPopup(provider).then(async (result) => {
          await this.sendUserDataToBackend()
          this.setUserAndRedirectToDashboard(result.user.email)
        }).catch((error) => {
          this.handleDuplicateAccountError(error, 'twitter')
        })
      },
      async _getSocialLoginToken (authPayload) {
        return await axios.post(`${this.firebaseCloudFunctionBaseUrl}/socialLogin`, authPayload)
      },
      async loginWithFacebook () {
        this.dialog = true
        const provider = new firebase.default.auth.FacebookAuthProvider()
        provider.addScope('email')
        firebase.default.auth().signInWithPopup(provider).then(async (result) => {
          await this.sendUserDataToBackend()
          this.setUserAndRedirectToDashboard(result.user.email)
        }).catch((error) => {
          this.handleDuplicateAccountError(error, 'facebook')
        })
      },
      async loginWithGoogle () {
        this.dialog = true
        const provider = new firebase.default.auth.GoogleAuthProvider()
        provider.addScope('email')
        firebase.default.auth().signInWithPopup(provider).then(async (result) => {
          await this.sendUserDataToBackend()
          this.setUserAndRedirectToDashboard(result.user.email)
        }).catch((error) => {
          this.handleDuplicateAccountError(error, 'google')
        })
      },
      async loginWithGithub () {
        this.dialog = true
        const provider = new firebase.default.auth.GithubAuthProvider()
        provider.addScope('user:email')
        firebase.default.auth().signInWithPopup(provider).then(async (result) => {
          await this.sendUserDataToBackend()
          this.setUserAndRedirectToDashboard(result.user.email)
        }).catch((error) => {
          this.handleDuplicateAccountError(error, 'github')
        })
      },
      async loginWithLinkedIn () {
        const linkedInConfig = {
          response_type: 'code',
          client_id: process.env.VUE_APP_LINKEDIN_CLIENT_ID,
          redirect_uri: process.env.VUE_APP_LINKEDIN_LOGIN_REDIRECT_URL,
          scope: 'r_liteprofile%20r_emailaddress%20w_member_social',
        }
        window.location = `https://www.linkedin.com/uas/oauth2/authorization?response_type=${linkedInConfig.response_type}&client_id=${linkedInConfig.client_id}&redirect_uri=${linkedInConfig.redirect_uri}&scope=${linkedInConfig.scope}`
      },
      _markAsDirty (fieldName) {
        this.fields[fieldName].isDirty = true
      },
      _updateFieldValue (fieldName, value) {
        this.fields[fieldName].value = value
        this._validateForm()
      },
      _validateForm () {
        let isFormValid = true
        Object.keys(this.fields).forEach((fieldName, index) => {
          this.fields[fieldName].isValid = true
          switch (fieldName) {
            case 'email': {
              var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              if (!emailRegex.test(String(this.fields[fieldName].value).toLowerCase())) {
                this.fields[fieldName].isValid = false
                isFormValid = false
              }
              break
            }
            case 'password': {
              if (!this.fields[fieldName].value || this.fields[fieldName].value.length === 0) {
                this.fields[fieldName].isValid = false
                isFormValid = false
              }
              break
            }
          }
        })
        this.isFormValid = isFormValid
      },
      async _checkIfUserIsVerified () {
        const idToken = await HttpConnector.getFirebaseClientToken()
        const decoded = jwtDecode(idToken)
        if (
          decoded &&
          decoded.firebase &&
          decoded.firebase.sign_in_provider &&
          decoded.firebase.sign_in_provider === 'password'
        ) {
          if (!decoded.email_verified) {
            // Manual signin, email not verified
            // So logout and send to no access page
            localStorage.clear()
            this.$router.push({
              path: `/no-access?email=${decoded.email}`,
              props: {
                userEmail: decoded.email,
              },
            })
            return false
          }
        }
        return true
      },
      _makeAllFieldDirty () {
        Object.keys(this.fields).forEach((fieldName, index) => {
          this.fields[fieldName].isDirty = true
        })
      },
      async _submitLoginForm (e) {
        this.generalError = {
          isVisible: false,
          errorText: '',
        }
        e.preventDefault()
        this._makeAllFieldDirty()
        this._validateForm()
        if (this.isFormValid) {
          try {
            this.dialog = true
            const email = this.fields.email.value
            const password = this.fields.password.value
            await firebase.default.auth().signInWithEmailAndPassword(email, password)
            if (await this._checkIfUserIsVerified()) {
              await this.sendUserDataToBackend()
              this.dialog = false
              localStorage.setItem('username', email)
              this.$router.push('/pages/senders')
            }
          } catch (error) {
            this.dialog = false
            this.notification = error.message
            this.snackbar = true
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";
@import '../../assets/bootstrap.min.css';
@import '../../assets/now-ui-kit.css';
.page-header {
  max-height: unset!important;
}
.social {
  button {
    margin: 0 5px;
  }
}
.inputError {
  font-size: 0.7rem;
  margin-top: -5px;
  margin-bottom: 6px;
  color: red;
  font-weight: 600;
  height: 15px;
}
.card-title {
  color: black;
  margin-bottom: 3rem!important;
}
.info-horizontal {
  margin-top: -6rem;
}
.contentWrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
      margin-top: 8rem;
  }
}
.authLinks {
  padding: 0 25px;
  justify-content: space-around;
  align-items: center;
  margin-top: -1.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  color: rgb(66, 66, 66);
  font-size: 0.8rem;
  div {
    cursor: pointer;
  }
}
</style>
